import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import Loader from "@icarius-common/loader";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TimelineIcon from '@material-ui/icons/Timeline';
import CommunicationChannelStatusRenderer from "@icarius-table/renderersAndFilters/communicationChannelStatusRenderer";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import RichTextRenderer from "@icarius-table/renderersAndFilters/richTextRenderer";
import useCommunicationChannel from "./useCommunicationChannel";
import HomeScreen from "./homeScreen";
import CreateEditFormDialog from "./createEditDialog";
import ActionFormDialog from "./actionDialog";
import Timeline from "./timeline";
import AssignResponsibleDialog from "./assignResponsibleDialog";
import ClassificationDialog from "./classificationDialog";

const CommunicationChannel = () => {

  const location = useLocation();
  const pendingCommunications = location?.state?.pendingCommunications || false;

  const ownGridRef = useRef(); // tengo que usar una ref propia para el aggrid porque tengo 3 commonPage y al salir y volver se pierden las ref internas
  const color = useSelector(getAppColor);

  const {
    isAdmin,
    indicators,
    showComplaints,
    statusList,
    classificationList,
    level,
    data,
    isLoading,
    isLoadingHome,
    state,
    handlers,
  } = useCommunicationChannel(pendingCommunications);

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('COMMUNICATION_CHANNEL');

  const customHeader = () => level === "E" && (
    <TemplatesHeader
      gridRef={ownGridRef.current}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  );

  const goBackButton = () => (level === "C" || (level === "M" && !pendingCommunications)) && (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleUnselectCommunication}
      type={"goBack"}
    />
  )

  const createButton = () => (level === "C" || (level === "M" && !pendingCommunications)) && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const assignResponsibleButton = () => level === "E" && (
    <CustomIconButton
      title={"Asignar responsable"}
      onClick={() => handlers.handleOpenAssignResponsibleDialog(ownGridRef.current)}
      type={"addPerson"}
    />
  )

  const createActionButton = () => (level === "E" || (level === "M" && pendingCommunications)) && (
    <CustomIconButton
      title={"Crear nueva acción o respuesta"}
      onClick={() => handlers.handleOpenActionCreateDialog(ownGridRef.current)}
      type={"questionsAnswer"}
    />
  )

  const modifyButton = () => (level === "C" || (level === "M" && !pendingCommunications)) && (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(ownGridRef.current)}
      type={"edit"}
    />
  )

  const deleteButton = () => (isAdmin || level === "C") && (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(ownGridRef.current)}
      type={"delete"}
    />
  )

  const classificationButton = () => level === 'E' && (
    <CustomIconButton
      title={'Clasificar la comunicación'}
      onClick={() => handlers.handleOpenClassificationDialog(ownGridRef.current)}
      type={"edit"}
    />
  )

  const timelineButton = () => (
    <CustomIconButton
      title={'Línea de tiempo'}
      onClick={() => handlers.handleOpenTimelineAgGrid(ownGridRef.current)}
    >
      <TimelineIcon />
    </CustomIconButton>
  )

  const getTitle = (type) => {
    switch (type) {
      case "C":
        return "Mis consultas";
      case "R":
        return "Mis reclamos";
      case "S":
        return "Mis sugerencias";
      case "D":
        return "Mis denuncias";
      default:
        return "";
    }
  }

  const handleRowClick = (row) => {
    handlers.handleOpenTimeline(row.data);
  }

  const subtitle = (() => {
    const subtitleText = (() => {
      if (isAdmin) return "Como administrador estás viendo todas las comunicaciones";
      if (level === 'E' || (level === 'M' && pendingCommunications)) return "Comunicaciones donde eres el responsable de la atención o en las cuales puedes auto asignarte como responsable de la atención";
      return null;
    })();

    if (subtitleText) return <b style={{ color: color }}>{subtitleText}</b>
    return null;
  })();

  return (
    <>
      {isLoadingHome && <Loader open={isLoadingHome} />}
      {
        !isLoadingHome &&
        state.selectedChannel === "" &&
        (level === "C" || (level === "M" && !pendingCommunications))
        &&
        <HomeScreen
          isLoading={isLoading}
          indicators={indicators}
          showComplaints={showComplaints}
          handleSelectCommunication={handlers.handleSelectCommunication}
        />
      }
      {
        (level === "E" || (level === "M" && pendingCommunications) || (level !== "E" && state.selectedChannel)) &&
        !state.timelineIsOpen &&
        <CommonPage
          isLoading={isLoadingTemplates || isLoading}
          title={level === "E" || (level === "M" && pendingCommunications) ? "Canal de comunicaciones" : getTitle(state.selectedChannel)}
          subtitle={subtitle}
          gridTitle={level === "E" || (level === "M" && pendingCommunications) ? "Canal de comunicaciones" : getTitle(state.selectedChannel)}
          columnDefPage={level === "E" || (level === "M" && pendingCommunications) ? paths.communicationChannel : "myCommunicationChannel"}
          rowData={data}
          menuItems={[
            goBackButton,
            timelineButton,
            assignResponsibleButton,
            createActionButton,
            createButton,
            modifyButton,
            classificationButton,
            deleteButton,
          ]}
          handleRowClick={handleRowClick}
          hasExpand
          hasHelp
          customHeader={customHeader}
          ownGridRef={ownGridRef}
          codeFieldName={'code'}
          frameworkComponents={{ CommunicationChannelStatusRenderer, RichTextRenderer }}
          hasSwitch
          handleSwitchClick={handlers.handleSwitchOnlyActives}
          switchValue={state.showOnlyActiveRows}
          switchLabelOn={'Activas'}
          switchLabelOff={'Todas'}
          gridHeight={Boolean(subtitle) ? 'calc(100vh - 220px)' : null}
        >
          {
            state.actionCreateDialogIsOpen &&
            <ActionFormDialog
              open={state.actionCreateDialogIsOpen}
              handleClose={handlers.handleCloseActionCreateDialog}
              data={state.selectedData}
              level={level}
              type={state.selectedChannel}
              statusList={statusList}
            />
          }
          {
            state.classificationDialogIsOpen &&
            <ClassificationDialog
              open={state.classificationDialogIsOpen}
              data={state.selectedData}
              classificationList={classificationList}
              handleClose={handlers.handleCloseClassificationDialog}
            />
          }
          {
            state.createDialogIsOpen &&
            <CreateEditFormDialog
              open={state.createDialogIsOpen}
              handleClose={handlers.handleCloseCreateEditDialog}
              data={state.selectedData}
              type={state.selectedChannel}
            />
          }
          {
            state.assignResponsibleDialogIsOpen &&
            <AssignResponsibleDialog
              open={state.assignResponsibleDialogIsOpen}
              handleClose={handlers.handleCloseAssignResponsibleDialog}
              data={state.selectedData}
              isLoading={isLoading}
            />
          }
        </CommonPage>
      }
      {
        state.timelineIsOpen &&
        <Timeline
          level={level}
          data={state.timelineData}
          handleGoBack={handlers.handleCloseTimeline}
        />
      }
      {
        level !== "E" && state.createDialogIsOpen &&
        <CreateEditFormDialog
          open={state.createDialogIsOpen}
          handleClose={handlers.handleCloseCreateEditDialog}
          data={state.selectedData}
          type={state.selectedChannel}
        />
      }
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(ownGridRef.current, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>
  )
}

export default CommunicationChannel;