import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createClassificationApplicantsTypesAction, modifyClassificationApplicantsTypesAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import ChangeColorDialog from "../dialogs/changeColorDialog";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    index,
    gridTheme,
    data,
    handleClose,
  } = props;

  const [changeColorDialogIsOpen, setChangeColorDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createClassificationApplicantsTypesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyClassificationApplicantsTypesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={'Código'}
              onChange={(e) => setFormValue(e.target.value, "code")}
              value={formData.code}
              inputProps={{ maxLength: 12 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Nombre'}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 200 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={3} style={{ padding: "0px 10px" }}>
            <Grid item xs={12}>
              <Typography className="whiteText" variant='caption'>{"Color de representación"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                onClick={() => setChangeColorDialogIsOpen(true)}
                style={{ border: gridTheme.theme === "dark" ? "1px solid #c4c4c4" : "1px solid black", width: 150, height: 24, backgroundColor: formData.color }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        changeColorDialogIsOpen &&
        <ChangeColorDialog
          open={changeColorDialogIsOpen}
          scoreColor={formData.color}
          handleCloseDialog={() => setChangeColorDialogIsOpen(false)}
          modifyItem={setFormValue}
          index={index}
        />
      }
    </>
  );
}

export default Form;
