import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getProgressIsOpen } from "@icarius-common/circularProgress/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  getCertificatesWithSignAction,
  getRegularCertificatesAndCollaboratorsAction,
  getCollaboratorsForCertificateAction,
} from "../actions";
import {
  getCollaborators,
  getRegularCertificates,
  getIsLoading,
  getDateFormat,
  getLocale,
  getCertificatesWithSign,
  getReceiverList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";

const useCertificates = (isDigitalSignVersion) => {

  const [selectedCertificate, setSelectedCertificate] = useState("");

  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const dispatch = useDispatch();
  const exportingGridStatus = useSelector(getIsExportingGrid);
  const isLoading = useSelector(getIsLoading);
  const isProgressLoading = useSelector(getProgressIsOpen);
  const collaborators = useSelector(getCollaborators);
  const regularCertificates = useSelector(getRegularCertificates);
  const certificatesWithSign = useSelector(getCertificatesWithSign);
  const receiverList = useSelector(getReceiverList);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);

  useEffect(() => {
    return () => dispatch({ type: RESET_STATE });
  }, [dispatch])

  const validateHasFiles = useCallback((res) => {
    if (res?.data?.certificates?.length) return true;
    dispatch(openSnackbarAction({ msg: getLocalizedString("noDocumentsToGenerate"), duration: null, severity: "warning" }));
    return false;
  }, [dispatch])

  useEffect(() => {
    /**
      si es la version de firma digital, ver si puede firmar
        si puede, traer los certif con firma digital
        si no puede, nada
    **/
    if (isDigitalSignVersion) {
      dispatch(getCertificatesWithSignAction())
        .then((res) => {
          if (validateHasFiles(res)) {
            dispatch(getCollaboratorsForCertificateAction("")); // traer los colaboradores iniciales
          }
        });
    }

    /**
      si no es la version con firma digital
        traer los certif comunes
    **/
    if (!isDigitalSignVersion) {
      dispatch(getRegularCertificatesAndCollaboratorsAction())
        .then((res) => validateHasFiles(res));
    }
  }, [dispatch, validateHasFiles, isDigitalSignVersion])

  const handleRowClick = (employeeRow) => {
    setSelectedEmployee(employeeRow.data["CODIGO DE EMPLEADO"]);
    setUserDialogIsOpen(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialogIsOpen(false);
    setSelectedEmployee(null);
  }

  const handleSelectCertificate = (newSelectedCertificate, gridRef) => {
    setSelectedCertificate(newSelectedCertificate);

    if (isDigitalSignVersion) {
      const certificateWithSign = certificatesWithSign.find((certificate) => certificate.reference === newSelectedCertificate);

      if (certificateWithSign) {
        // trar colaboradores para el certificado con firma seleccionado
        dispatch(getCollaboratorsForCertificateAction(certificateWithSign.code, certificateWithSign.reference, certificateWithSign.role));
      }
    }

    setFilterForGrid(gridRef);
    gridRef.api.clearRangeSelection();
    gridRef.api.deselectAll();
  };

  const setFilterForGrid = (gridRef) => {
    let filterInstance = gridRef.api.getFilterInstance("PERSONAL DE PLANTA");
    filterInstance.setModel({ type: "set", values: ["Si"] });

    let filterInstanceVigencia = gridRef.api.getFilterInstance("ESTADO");
    filterInstanceVigencia.setModel({ type: "set", values: ["Vigente"] });

    gridRef.api.onFilterChanged();
  }

  const state = {
    selectedCertificate,
    userDialogIsOpen,
    selectedEmployee,
  }

  const handlers = {
    handleSelectCertificate,
    handleRowClick,
    handleCloseUserDialog,
  }

  return {
    isDigitalSignVersion,
    isLoading: isLoading || exportingGridStatus,
    isProgressLoading,
    collaborators,
    certificateList: isDigitalSignVersion ? certificatesWithSign : regularCertificates,
    receiverList,
    dateFormat,
    locale,
    state,
    handlers,
  }
}

export default useCertificates;