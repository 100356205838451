import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    timeFormatter,
    dateValueGetter,
    timeValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberFilterParams,
    numberFormatterMin0Max0,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Código de empleado',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },

    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Apellido y nombre',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Fecha de ingreso',
            field: 'date',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },

    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Hora de ingreso',
            field: 'time',
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.communicationChannel],
        config: {
            headerName: 'Días transcurridos',
            field: 'daysPassed',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.communicationChannel],
        config: {
            headerName: 'Tipo de comunicación',
            field: 'typeString',
            filter: "agSetColumnFilter",
            cellRenderer: "CommunicationChannelStatusRenderer",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Estado de la comunicación',
            field: 'statusString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Responsable de la atención',
            field: 'responsibleName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Cantidad de acciones o respuestas',
            field: 'actionsQuantity',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel],
        config: {
            headerName: 'Cantidad de adjuntos',
            field: 'attachmentsAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Fecha asignación responsable',
            field: 'responsibleDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Hora asignación responsable',
            field: 'responsibleTime',
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.communicationChannel,],
        config: {
            headerName: 'Asignador del responsable',
            field: 'allocatorName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel],
        config: {
            headerName: 'Clasificación',
            field: 'classificationString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Comentarios',
            field: 'comment',
            cellRenderer: "RichTextRenderer",
        }
    },
    {
        pages: [paths.communicationChannel, "myCommunicationChannel"],
        config: {
            headerName: 'Fecha de finalización',
            field: 'endDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
]