import * as actionTypes from "./actionTypes";
import {
  getCommunicationChannelAPI,
  createCommunicationChannelAPI,
  updateCommunicationChannelAPI,
  deleteCommunicationChannelAPI,
  assignResponsibleCommunicationChannelAPI,
  answerCommunicationChannelAPI,
  classifyCommunicationChannelAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un formulario de desempeño con el mismo código, tipo y grupo de cargos a evaluar';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const clearDataFromStore = () => dispatch => dispatch({ type: actionTypes.RESET_DATA });

export const getCommunicationChannelAction = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response;

    if (type) {
      let params = new URLSearchParams();
      params.set("type", type);
      response = await getCommunicationChannelAPI(`?${params.toString()}`);
    } else {
      response = await getCommunicationChannelAPI("");
    }

    let data = response.data && response.data.data;
    let showComplaints = response.data && response.data.showComplaints;
    let classificationList = response.data && response.data.classificationList;
    let indicators = response.data && response.data.indicators;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        showComplaints,
        indicators,
        classificationList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getShowComplaintsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.SHOW_COMPLAINTS });
  try {
    let params = new URLSearchParams();
    params.set("type", "R");
    let response = await getCommunicationChannelAPI(`?${params.toString()}`);

    let showComplaints = response.data && response.data.showComplaints;
    let status = response.data && response.data.status;
    let indicators = response.data && response.data.indicators;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SHOW_COMPLAINTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.SHOW_COMPLAINTS_FULFILLED,
      payload: { showComplaints, indicators },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SHOW_COMPLAINTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};


export const createCommunicationChannelAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (['attachment', 'type'].includes(key)) {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await createCommunicationChannelAPI(formData);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateCommunicationChannelAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (['attachment', 'type', 'internalCode'].includes(key)) {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await updateCommunicationChannelAPI(formData);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: { data },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteCommunicationChannelAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteCommunicationChannelAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Comunicación eliminada correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
  }
};

export const answerCommunicationAction = (dataToSend, level) => async (dispatch) => {
  dispatch({ type: actionTypes.ANSWER_COMMUNICATION });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (['attachment', 'type', 'internalCode', 'status', 'privateComment'].includes(key)) {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });
    let response = await answerCommunicationChannelAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ANSWER_COMMUNICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ANSWER_COMMUNICATION_FULFILLED,
      payload: { data },
    });
    if (level === "E") {
      dispatch(openSnackbarAction({ msg: "Acción creada correctamente", severity: "success" }));
    } else {
      dispatch(openSnackbarAction({ msg: "Gracias por responder. Ahora la comunicación ha retornado al Empleador responsable para continuar con su atención.", duration: null, severity: "success" }));

    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ANSWER_COMMUNICATION_REJECTED, payload: e });
  }
};

export const assignResponsibleAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE });
  try {
    let response = await assignResponsibleCommunicationChannelAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ASSIGN_RESPONSIBLE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Responsable asignado correctamente. La comunicación ahora será visualizada solamente por el nuevo responsable.", duration: null, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ASSIGN_RESPONSIBLE_REJECTED, payload: e });
  }
};

export const classifyCommunicationChannelAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CLASSIFY });
  try {
    let response = await classifyCommunicationChannelAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CLASSIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CLASSIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Comunicación clasificada correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CLASSIFY_REJECTED, payload: e });
  }
};