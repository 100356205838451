import NAME from "./constants";

export const GET_REGULAR_CERTIFICATES_AND_COLLABORATORS = NAME + "/GET_REGULAR_CERTIFICATES_AND_COLLABORATORS";
export const GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_FULFILLED = NAME + "/GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_FULFILLED";
export const GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED = NAME + "/GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED";

export const GET_CERTIFICATES_WITH_SIGN = NAME + "/GET_CERTIFICATES_WITH_SIGN";
export const GET_CERTIFICATES_WITH_SIGN_FULFILLED = NAME + "/GET_CERTIFICATES_WITH_SIGN_FULFILLED";
export const GET_CERTIFICATES_WITH_SIGN_REJECTED = NAME + "/GET_CERTIFICATES_WITH_SIGN_REJECTED";

export const GET_COLLABORATORS_FOR_CERTIFICATE = NAME + "/GET_COLLABORATORS_FOR_CERTIFICATE";
export const GET_COLLABORATORS_FOR_CERTIFICATE_FULFILLED = NAME + "/GET_COLLABORATORS_FOR_CERTIFICATE_FULFILLED";
export const GET_COLLABORATORS_FOR_CERTIFICATE_REJECTED = NAME + "/GET_COLLABORATORS_FOR_CERTIFICATE_REJECTED";

export const DOWNLOAD_MULTIPLE_CERTIFICATES = NAME + "/DOWNLOAD_MULTIPLE_CERTIFICATES";
export const DOWNLOAD_MULTIPLE_CERTIFICATES_FULFILLED = NAME + "/DOWNLOAD_MULTIPLE_CERTIFICATES_FULFILLED";
export const DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED = NAME + "/DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED";

export const SIGN_AND_DOWNLOAD = NAME + "/SIGN_AND_DOWNLOAD";
export const SIGN_AND_DOWNLOAD_FULFILLED = NAME + "/SIGN_AND_DOWNLOAD_FULFILLED";
export const SIGN_AND_DOWNLOAD_REJECTED = NAME + "/SIGN_AND_DOWNLOAD_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";