import React from "react";

const QuestionsIcon = (props) => (
    <svg {...props} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7226_112)">
            <path d="M31.5255 39.4741L31.5264 39.4741C35.3436 39.4721 39.1115 40.3689 42.5384 42.0965C45.9654 43.8242 48.9609 46.3373 51.2909 49.4436C53.6209 52.55 55.2224 56.1659 55.9689 60.0115C56.7155 63.8572 56.5865 67.8271 55.5923 71.6132C54.5982 75.3993 52.7661 78.8977 50.2403 81.8385C47.7147 84.779 44.5638 87.0827 41.033 88.5737C37.5024 90.0647 33.6851 90.7038 29.8764 90.4428C26.0676 90.1818 22.3668 89.0273 19.0607 87.067L18.0303 86.456L17.2066 87.3257C14.7856 89.8817 11.72 90.4067 9.11499 90.2466C8.73892 90.2235 8.37562 90.1861 8.02923 90.139C8.4069 89.8785 8.78989 89.5922 9.16409 89.2806C11.1029 87.6661 13.2205 84.9989 12.3434 81.4805L12.2663 81.1714L12.0703 80.9203C9.13995 77.1668 7.30265 72.6397 6.77381 67.8594C6.24496 63.079 7.04664 58.2442 9.08458 53.9122C11.1224 49.5804 14.3121 45.9301 18.2823 43.3777C22.2522 40.8256 26.8425 39.4737 31.5255 39.4741Z" fill="#FF592C" stroke="var(--svgColor)" strokeWidth="3" />
            <path d="M33.0151 70.3442H30.0752C30.084 69.5269 30.1543 68.8413 30.2861 68.2876C30.418 67.7251 30.6377 67.2153 30.9453 66.7583C31.2617 66.3013 31.6792 65.8179 32.1978 65.3081C32.6021 64.9214 32.9668 64.5566 33.292 64.2139C33.6172 63.8623 33.8765 63.4932 34.0698 63.1064C34.2632 62.7109 34.3599 62.2583 34.3599 61.7485C34.3599 61.1948 34.2676 60.7246 34.083 60.3379C33.8984 59.9512 33.626 59.6567 33.2656 59.4546C32.9141 59.2524 32.4746 59.1514 31.9473 59.1514C31.5078 59.1514 31.0947 59.2393 30.708 59.415C30.3213 59.582 30.0093 59.8457 29.772 60.2061C29.5347 60.5576 29.4072 61.0234 29.3896 61.6035H26.2124C26.23 60.4961 26.4937 59.5645 27.0034 58.8086C27.5132 58.0527 28.1987 57.4858 29.0601 57.1079C29.9214 56.73 30.8838 56.541 31.9473 56.541C33.125 56.541 34.1313 56.7432 34.9663 57.1475C35.8013 57.543 36.4385 58.123 36.8779 58.8877C37.3262 59.6436 37.5503 60.5576 37.5503 61.6299C37.5503 62.4033 37.3965 63.1064 37.0889 63.7393C36.7812 64.3633 36.3813 64.9478 35.8892 65.4927C35.397 66.0288 34.8652 66.5649 34.2939 67.1011C33.8018 67.5493 33.4678 68.0371 33.292 68.5645C33.1162 69.083 33.0239 69.6763 33.0151 70.3442ZM29.8115 74.4575C29.8115 73.9829 29.9741 73.583 30.2993 73.2578C30.6245 72.9238 31.0684 72.7568 31.6309 72.7568C32.1934 72.7568 32.6372 72.9238 32.9624 73.2578C33.2876 73.583 33.4502 73.9829 33.4502 74.4575C33.4502 74.9321 33.2876 75.3364 32.9624 75.6704C32.6372 75.9956 32.1934 76.1582 31.6309 76.1582C31.0684 76.1582 30.6245 75.9956 30.2993 75.6704C29.9741 75.3364 29.8115 74.9321 29.8115 74.4575Z" fill="white" />
            <path d="M79.4745 5.47412L79.4736 5.47412C75.6564 5.47211 71.8885 6.36886 68.4616 8.0965C65.0346 9.82423 62.0391 12.3373 59.7091 15.4436C57.3791 18.55 55.7776 22.1659 55.0311 26.0115C54.2845 29.8572 54.4135 33.8271 55.4077 37.6132C56.4018 41.3993 58.2339 44.8977 60.7597 47.8385C63.2853 50.779 66.4362 53.0827 69.967 54.5737C73.4976 56.0647 77.3149 56.7038 81.1236 56.4428C84.9324 56.1818 88.6332 55.0273 91.9393 53.067L92.9697 52.456L93.7934 53.3257C96.2144 55.8817 99.28 56.4067 101.885 56.2466C102.261 56.2235 102.624 56.1861 102.971 56.139C102.593 55.8785 102.21 55.5922 101.836 55.2806C99.8971 53.6661 97.7795 50.9989 98.6566 47.4805L98.7337 47.1714L98.9297 46.9203C101.86 43.1668 103.697 38.6397 104.226 33.8594C104.755 29.079 103.953 24.2442 101.915 19.9122C99.8776 15.5804 96.6879 11.9301 92.7177 9.37774C88.7478 6.8256 84.1575 5.47372 79.4745 5.47412Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" />
            <path d="M80.0151 35.3442H77.0752C77.084 34.5269 77.1543 33.8413 77.2861 33.2876C77.418 32.7251 77.6377 32.2153 77.9453 31.7583C78.2617 31.3013 78.6792 30.8179 79.1978 30.3081C79.6021 29.9214 79.9668 29.5566 80.292 29.2139C80.6172 28.8623 80.8765 28.4932 81.0698 28.1064C81.2632 27.7109 81.3599 27.2583 81.3599 26.7485C81.3599 26.1948 81.2676 25.7246 81.083 25.3379C80.8984 24.9512 80.626 24.6567 80.2656 24.4546C79.9141 24.2524 79.4746 24.1514 78.9473 24.1514C78.5078 24.1514 78.0947 24.2393 77.708 24.415C77.3213 24.582 77.0093 24.8457 76.772 25.2061C76.5347 25.5576 76.4072 26.0234 76.3896 26.6035H73.2124C73.23 25.4961 73.4937 24.5645 74.0034 23.8086C74.5132 23.0527 75.1987 22.4858 76.0601 22.1079C76.9214 21.73 77.8838 21.541 78.9473 21.541C80.125 21.541 81.1313 21.7432 81.9663 22.1475C82.8013 22.543 83.4385 23.123 83.8779 23.8877C84.3262 24.6436 84.5503 25.5576 84.5503 26.6299C84.5503 27.4033 84.3965 28.1064 84.0889 28.7393C83.7812 29.3633 83.3813 29.9478 82.8892 30.4927C82.397 31.0288 81.8652 31.5649 81.2939 32.1011C80.8018 32.5493 80.4678 33.0371 80.292 33.5645C80.1162 34.083 80.0239 34.6763 80.0151 35.3442ZM76.8115 39.4575C76.8115 38.9829 76.9741 38.583 77.2993 38.2578C77.6245 37.9238 78.0684 37.7568 78.6309 37.7568C79.1934 37.7568 79.6372 37.9238 79.9624 38.2578C80.2876 38.583 80.4502 38.9829 80.4502 39.4575C80.4502 39.9321 80.2876 40.3364 79.9624 40.6704C79.6372 40.9956 79.1934 41.1582 78.6309 41.1582C78.0684 41.1582 77.6245 40.9956 77.2993 40.6704C76.9741 40.3364 76.8115 39.9321 76.8115 39.4575Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_7226_112">
                <rect x="111.391" y="0.772461" width="95" height="111" rx="2" transform="rotate(90 111.391 0.772461)" fill="white" />
            </clipPath>
        </defs>
    </svg>

);

export default QuestionsIcon;
