import React from "react";

const SuggestionIcon = (props) => (
    <svg {...props} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7226_131)">
            <path d="M43.3068 27.3793L43.2004 27.3127C41.7493 26.4058 42.0333 24.2138 43.6676 23.7066L82.7104 11.5899C82.9024 11.5303 83.1022 11.5 83.3032 11.5H90.1248C90.3728 11.5 90.6186 11.5461 90.8497 11.636L99.1081 14.8476C99.3665 14.9481 99.6013 15.1013 99.7974 15.2974L103.22 18.7202C103.405 18.905 103.552 19.1241 103.652 19.3652L105.846 24.6308C105.948 24.8745 106 25.1359 106 25.4V54.6796C106 55.4805 105.522 56.2041 104.786 56.5188L51.3764 79.3392C51.1281 79.4453 50.8607 79.5 50.5906 79.5H50.5C49.3954 79.5 48.5 78.6046 48.5 77.5V35.3627C48.5 35.1228 48.4569 34.885 48.3727 34.6604L47.1521 31.4056C47.0519 31.1383 46.8955 30.8955 46.6937 30.6937L43.6611 27.6611C43.554 27.554 43.4352 27.4595 43.3068 27.3793Z" fill="#F5A506" />
            <path d="M49 79.5V37.4742C49 32.6099 46.1244 28.2056 41.6709 26.2489V26.2489C37.1132 24.2465 32 27.5849 32 32.5632L32 70.7091" stroke="var(--svgColor)" strokeWidth="3" />
            <g clipPath="url(#clip1_7226_131)">
                <path d="M24.3794 62.527L23.5243 44.9997L54.5 39.4997L54.5 55.4634C54.5 56.3695 53.8908 57.1624 53.0153 57.3959L26.8923 64.3621C25.6614 64.6903 24.4414 63.7994 24.3794 62.527Z" fill="#FF4040" />
                <path d="M23.5243 44.9997L24.3794 62.527C24.4414 63.7994 25.6614 64.6903 26.8923 64.3621L53.0153 57.3959C53.8908 57.1624 54.5 56.3695 54.5 55.4634L54.5 39.4997M23.5243 44.9997L54.5 39.4997M23.5243 44.9997L41.0535 53.7756C41.8972 54.198 42.9212 53.9733 43.5106 53.2366L54.5 39.4997" stroke="var(--svgColor)" strokeWidth="3" strokeLinejoin="round" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M50.5 77.972L50.5 38.0002L47.5 38.0002L47.5 77.6337L40.7551 73.7041L40.7532 73.703L32.9458 69.1698L32.5965 68.9669L32.1926 68.9669L7.51925 68.9669C2.86765 68.9669 0.737012 74.7626 4.281 77.7755C7.29412 80.3371 11.12 81.7436 15.0749 81.7436L49 81.7436L49.3069 81.7436L49.5891 81.6231L107.237 57.0035L107.513 57.0035L107.513 56.8857L107.635 56.8336L107.513 56.5479L107.513 27.6969C107.513 21.9599 104.531 16.6353 99.6398 13.6374C93.6454 9.96336 86.3551 9.06066 79.6456 11.1616L38.4264 24.0687L39.3228 26.9316L80.5421 14.0246C86.4247 12.1825 92.8165 12.974 98.0722 16.1952C102.074 18.6477 104.513 23.0036 104.513 27.6969L104.513 54.9048L50.5 77.972ZM15.0749 78.7436L43.4456 78.7436L39.2468 76.2974L39.2449 76.2963L31.7887 71.9669L7.51925 71.9669C5.65888 71.9669 4.80675 74.2849 6.22414 75.4899C8.69483 77.5903 11.832 78.7436 15.0749 78.7436Z" fill="var(--svgColor)" />
        </g>
        <defs>
            <clipPath id="clip0_7226_131">
                <rect x="111.5" y="0.772461" width="95" height="111" rx="2" transform="rotate(90 111.5 0.772461)" fill="var(--svgColor)" />
            </clipPath>
            <clipPath id="clip1_7226_131">
                <rect width="37" height="31" fill="var(--svgColor)" transform="translate(50 34) rotate(90)" />
            </clipPath>
        </defs>
    </svg>
);

export default SuggestionIcon;
