import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";
export const RESET_DATA = NAME + "/RESET_DATA";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const UPDATE = NAME + "/UPDATE";
export const UPDATE_REJECTED = NAME + "/UPDATE_REJECTED";
export const UPDATE_FULFILLED = NAME + "/UPDATE_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";

export const CLASSIFY = NAME + "/CLASSIFY";
export const CLASSIFY_REJECTED = NAME + "/CLASSIFY_REJECTED";
export const CLASSIFY_FULFILLED = NAME + "/CLASSIFY_FULFILLED";

export const ASSIGN_RESPONSIBLE = NAME + "/ASSIGN_RESPONSIBLE";
export const ASSIGN_RESPONSIBLE_REJECTED = NAME + "/ASSIGN_RESPONSIBLE_REJECTED";
export const ASSIGN_RESPONSIBLE_FULFILLED = NAME + "/ASSIGN_RESPONSIBLE_FULFILLED";

export const ANSWER_COMMUNICATION = NAME + "/ANSWER_COMMUNICATION";
export const ANSWER_COMMUNICATION_REJECTED = NAME + "/ANSWER_COMMUNICATION_REJECTED";
export const ANSWER_COMMUNICATION_FULFILLED = NAME + "/ANSWER_COMMUNICATION_FULFILLED";

export const SHOW_COMPLAINTS = NAME + "/SHOW_COMPLAINTS";
export const SHOW_COMPLAINTS_REJECTED = NAME + "/SHOW_COMPLAINTS_REJECTED";
export const SHOW_COMPLAINTS_FULFILLED = NAME + "/SHOW_COMPLAINTS_FULFILLED";