import React from "react";
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getLocalizedString } from "@icarius-localization/strings";
import { assignResponsibleAction } from "../../actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75 };

const Form = (props) => {

    const {
        data,
        handleClose,
    } = props;

    const dispatch = useDispatch();


    const update = (dataToSend) => {
        dispatch(assignResponsibleAction(dataToSend))
            .then((res) => {
                if (res?.status === 200 && handleClose) {
                    handleClose();
                }
            });
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'Debe ingresar el responsable de la comunicación', severity: "error", duration: 10000 }));
    }

    const {
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, update, openValidationError);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography variant="h6" className="whiteText" style={{ marginTop: -15 }}>
                    {`${data.typeString} ${data.name ? `de ${data.name}` : "anónima"} el ${data.date} a las ${data.time}`}
                </Typography>
            </div>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id={'label-responsible'}>{'Nuevo responsable de la atención'}</InputLabel>
                    <Select
                        value={formData.employerCode}
                        labelId={`label-employerCode`}
                        id={`select-employerCode`}
                        onChange={(e) => setFormValue(e.target.value, "employerCode")}
                        margin={"none"}
                    >
                        {
                            data.employers.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <DialogActions>
                <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
            </DialogActions>
        </>
    )
}

export default Form;