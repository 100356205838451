import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { DialogContent, Grid, Button } from "@material-ui/core";
import Loader from "@icarius-common/loader";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";
import { useSelector } from "react-redux";
import { getAppColor, getCountry } from "src/app/selectors";
import { certificatesEmployeeFields } from "@icarius-pages/certificates/constants";
import DynamicInput from "@icarius-common/dynamicInputs";
import { TABS } from "@icarius-utils/constants";
import { TabPanel } from "@icarius-pages/directory/components/dialog/dialogContent";
import { GetAppIcon } from "@icarius-icons/index";
import UserSummary from "./userSummary";

const UserDialogContent = (props) => {

  const {
    employee,
    hideLoading,
    isLoading,
    handleGenerate,
  } = props;

  const country = useSelector(getCountry);
  const color = useSelector(getAppColor);

  return (
    <DialogContent id={"employee-dialog-content"} style={{ padding: "0px 48px 8px 48px" }}>
      {
        !hideLoading &&
        <Loader open={isLoading} />
      }
      {
        employee &&
        <>
          <UserSummary
            employee={employee}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant={"contained"}
              className="whiteText"
              startIcon={<GetAppIcon style={{ fill: color }} />}
              onClick={() => handleGenerate([employee["CODIGO DE EMPLEADO"]])}
            >
              {getLocalizedString("generate")}
            </Button>
          </div>
          <section style={{ marginTop: 15, width: "100%" }}>
            {
              [
                {
                  key: TABS.personal,
                  title: getLocalizedString("personales"),
                },
                {
                  key: TABS.contract,
                  title: getLocalizedString("contrato"),
                },
                {
                  key: TABS.remuneration,
                  title: getLocalizedString("remuneracion"),
                },
                {
                  key: TABS.location,
                  title: getLocalizedString("ubicacion"),
                },
                {
                  key: TABS.time,
                  title: getLocalizedString("tiempos"),
                },
              ].map((tab) => {
                return (
                  <TabPanel title={tab.title} key={tab.key}>
                    {
                      certificatesEmployeeFields
                        .filter((field) => field.tab === tab.key)
                        .map((field, index) => {

                          if (country === "AR" && (field.name === "COMUNA" || field.name === "REGION")) {
                            if (field.name === "REGION") return null;
                            field.label = getLocalizedString("localidad");
                          }

                          if (["HORARIO", "INASISTENCIA"].includes(field.name) && !employee[field.name]) return null;

                          const value = (() => {
                            if (["SUELDO BASICO", "HORAS DIARIAS CONTRATO", "HORAS MENSUALES CONTRATO"].includes(field.name)) {
                              return formatNumberOrReturnUndefined(employee[field.name], 2);
                            }

                            return employee[field.name];
                          })();

                          const addressHref = "https://www.google.com/maps/search/?api=1&query=" +
                            value +
                            (employee["COMUNA"] ? "," + employee["COMUNA"] : "") +
                            (employee["PROVINCIA"] ? "," + employee["PROVINCIA"] : "") +
                            (employee["PAIS"] ? "," + employee["PAIS"] : "")

                          return (
                            <Grid item xs={12} sm={6} key={index}>
                              <DynamicInput
                                label={field.label}
                                value={value}
                                readOnlyType={field.type}
                                addressHref={field.name === "DIRECCION" ? addressHref : null}
                              />
                            </Grid>
                          )
                        })
                    }
                  </TabPanel>
                )
              })
            }
          </section>
        </>
      }
    </DialogContent>
  );
}

export default UserDialogContent;