import { useState } from "react";

const useHandleForm = (data, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = () => {
      return isCreate ? "" : data.responsibleCode;
    }

    const fieldNames = [
      'employerCode',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    return Boolean(formData.employerCode);
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      modifyCallback({ internalCode: data.internalCode, ...dataToSend });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
