import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import Loader from "@icarius-common/loader";
import DialogTransition from "@icarius-common/dialogTransition";
import Form from "./form";

const getTypeName = (type) => {
    switch (type) {
        case "S":
            return "sugerencia";
        case "D":
            return "denuncia"
        case "R":
            return "reclamo";
        case "C":
            return "consulta";
        default:
            return "";
    }
}
const CreateEditDialog = (props) => {

    const {
        open,
        data,
        type,
        isLoading,
        handleAfterCreateCallback,
        handleClose,
    } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {`${Boolean(data) ? 'Editar' : 'Crear'} ${getTypeName(type)}`}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Loader open={isLoading} />
                <Form
                    type={type}
                    typeString={getTypeName(type)}
                    data={data}
                    handleClose={handleClose}
                    handleAfterCreateCallback={handleAfterCreateCallback}
                />
            </DialogContent>
        </Dialog>
    )
}

export default CreateEditDialog;