import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getCentersSettings,
  createCentersSettings,
  updateCentersSettings,
  deleteCentersSettings,
} from "@icarius-connection/api";

export const getCentersSettingsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CENTERS_SETTINGS });
  try {
    let response = await getCentersSettings();

    dispatch({
      type: actionTypes.GET_CENTERS_SETTINGS_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CENTERS_SETTINGS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

//UPDATES
export const updateCentersSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_CENTERS_SETTINGS });
  try {
    let response = await updateCentersSettings({ centersSettings: data });
    
    dispatch({ type: actionTypes.UPDATE_CENTERS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("centersUpdatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("centerDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.UPDATE_CENTERS_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};

//CREATE
export const createCentersSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_CENTERS_SETTINGS });
  try {
    let response = await createCentersSettings({ centersSettings: data });
    
    dispatch({ type: actionTypes.CREATE_CENTERS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("centersCreatedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("centerDuplicatedData"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_CENTERS_SETTINGS_REJECTED, payload: e });
    return e.response;
  }
};

//DELETE
export const deleteCentersSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_CENTERS_SETTINGS });
  try {
    let response = await deleteCentersSettings(data);
    
    dispatch({ type: actionTypes.DELETE_CENTERS_SETTINGS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("centerDeletedOK"), severity: "success" }));

    return response;
  } catch (e) {
    if(e.response.data.status === "MANAGEMENT_CENTER_IN_USE"){
      dispatch(openSnackbarAction({ msg: getLocalizedString("cantDeleteCenter"), severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_CENTERS_SETTINGS_REJECTED, payload: e });    
    return e.response;
  }
};
