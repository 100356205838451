import { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  previewDocumentsAction,
} from "../actions";

const useCertificatesPreview = (certificateList, selectedCertificate) => {

  const [certificatesToPreview, setCertificatesToPreview] = useState(null);
  const [previewReceiverSelectionDialogIsOpen, setPreviewReceiverSelectionDialogIsOpen] = useState(null);

  const dispatch = useDispatch();

  const handleValidateAndPreviewCertificates = (gridRef) => {
    const selectedRows = gridRef.api.getSelectedRows();

    if (selectedCertificate === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCertificateType"), severity: "warning" }));
      return;
    }

    if (!selectedRows.length) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    const auxCertificatesToPreview = selectedRows.map((item) => {
      return {
        "code": item.dataDoc.code,
        "codRef": item.dataDoc.codRef,
        "dataCode": item.dataDoc.dataCode,
        "role": item.dataDoc.destRole,
      }
    });

    const requiresReceiverSelection = certificateList.find((item) => item.reference === selectedCertificate)?.requiresReceiverSelection;

    if (requiresReceiverSelection) {
      setCertificatesToPreview(auxCertificatesToPreview);
      setPreviewReceiverSelectionDialogIsOpen(true);
    } else {
      previewCertificates(auxCertificatesToPreview);
    }
  };

  const previewCertificates = (docsToSend, receiver) => {
    dispatch(previewDocumentsAction(docsToSend, receiver));
  }

  const handleClosePreviewReceiverSelectionDialog = () => {
    setPreviewReceiverSelectionDialogIsOpen(false);
    setCertificatesToPreview(null);
  }

  const state = {
    certificatesToPreview,
    previewReceiverSelectionDialogIsOpen,
  }

  const handlers = {
    handleValidateAndPreviewCertificates,
    handleClosePreviewReceiverSelectionDialog,
    previewCertificates,
  }

  return {
    state,
    handlers,
  }
}

export default useCertificatesPreview;