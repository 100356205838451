import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAppColor, getGeographicalDivisions } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Tooltip,
  InputLabel
} from "@material-ui/core";
import { GetAppIcon, VisibilityIcon } from "@icarius-icons";
import CommonPage from "@icarius-common/commonPage";
import ConfirmPinDialog from "@icarius-common/confirmPinDialog";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { applyGeographicalDivision } from "@icarius-table/utils";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import paths from "@icarius-localization/paths";
import UserDialog from "./userDialog";
import ReceiverSelectionDialog from "./receiverSelectionDialog";
import useCertificates from "./useCertificates";
import useCertificatesPreview from "./useCertificatesPreview";
import useCertificatesDownload from "./useCertificatesDownload";

const Certificates = ({ match }) => {

  const {
    isDigitalSignVersion,
    isLoading,
    isProgressLoading,
    collaborators,
    certificateList,
    receiverList,
    dateFormat,
    locale,
    state,
    handlers,
  } = useCertificates(match.path === paths.digitalCertificates);

  const {
    state: previewState,
    handlers: previewHandlers,
  } = useCertificatesPreview(certificateList, state.selectedCertificate);

  const {
    state: downloadState,
    handlers: downloadHandlers,
  } = useCertificatesDownload(certificateList, collaborators, isDigitalSignVersion, state.selectedCertificate);

  const color = useSelector(getAppColor);
  const geographicalDivisions = useSelector(getGeographicalDivisions);

  const ownColumnDef = useMemo(() => {
    return applyGeographicalDivision(getColumnDefByPage(match.path), geographicalDivisions);
  }, [match, geographicalDivisions])

  const regularCertificateSelect = (gridRef) =>
    <>
      <FormControl style={{ minWidth: 275, maxWidth: 275 }}>
        <InputLabel id="select-label">{getLocalizedString("selectTypeOfDocument")}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={state.selectedCertificate}
          onChange={e => handlers.handleSelectCertificate(e.target.value, gridRef)}
        >
          {
            certificateList.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item.code}
                >
                  {item.name}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      <Tooltip title={getLocalizedString("generateAndDownloadNoSign")}>
        <IconButton aria-label="add" onClick={() => downloadHandlers.handleValidateAndDownloadCertificatesByGrid(gridRef)}>
          <GetAppIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
    </>

  const certificateWithSignSelect = (gridRef) =>
    <>
      <FormControl style={{ minWidth: 275, maxWidth: 275 }}>
        <InputLabel id="select-label">{getLocalizedString("selectTypeOfDocument")}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={state.selectedCertificate}
          onChange={e => handlers.handleSelectCertificate(e.target.value, gridRef)}
        >
          {
            certificateList.map((item) => {
              return (
                <MenuItem
                  key={item.reference}
                  value={item.reference}
                >
                  {item.name}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      <Tooltip title={getLocalizedString("previewDocuments")}>
        <IconButton onClick={() => previewHandlers.handleValidateAndPreviewCertificates(gridRef)}>
          <VisibilityIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
      <Tooltip title={getLocalizedString("generateAndDownloadWithSign")}>
        <IconButton aria-label="add" onClick={() => downloadHandlers.handleValidateAndDownloadCertificatesByGrid(gridRef)}>
          <GetAppIcon htmlColor={color} />
        </IconButton>
      </Tooltip>
    </>

  const helpItems = [
    <MenuItemWithIcon key={"a"} value={"/resources/ICARIUS - Firma Digital de documentos.pdf"} text={getLocalizedString("digitalSignature")} type={"file"} />,
  ]

  return (
    <CommonPage
      title={getLocalizedString(isDigitalSignVersion ? "certificateDigitalFullTitle" : "certificateFullTitle")}
      gridTitle={getLocalizedString(isDigitalSignVersion ? "certificateDigitalFullTitle" : "certificateFullTitle")}
      helpItems={helpItems}
      customHeader={isDigitalSignVersion ? certificateWithSignSelect : regularCertificateSelect}
      rowData={collaborators}
      locale={locale}
      dateFormat={dateFormat}
      ownColumnDef={ownColumnDef}
      handleRowClick={handlers.handleRowClick}
      isLoading={isLoading}
      hideLoading={isProgressLoading}
      hasExpand
      hasHelp
    >
      {
        state.userDialogIsOpen &&
        <UserDialog
          open={state.userDialogIsOpen}
          isLoading={isLoading}
          hideLoading={isProgressLoading}
          employee={collaborators.find((employee) => employee["CODIGO DE EMPLEADO"] === state.selectedEmployee)}
          handleGenerate={downloadHandlers.handleValidateAndDownloadCertificatesByUserDialog}
          handleClose={handlers.handleCloseUserDialog}
        />
      }
      {
        downloadState.pinDialogOpen &&
        <ConfirmPinDialog
          open={downloadState.pinDialogOpen}
          text={getLocalizedString("confirmAcceptRequestedDocuments")}
          requiresSelection={certificateList.find((cert) => cert.reference === state.selectedCertificate)?.multipleApprovation}
          selectionData={certificateList.find((cert) => cert.reference === state.selectedCertificate)?.approvants}
          handleConfirm={downloadHandlers.handleSign}
          handleClose={downloadHandlers.handleClosePinDialog}
        />
      }
      {
        previewState.previewReceiverSelectionDialogIsOpen &&
        <ReceiverSelectionDialog
          open={previewState.previewReceiverSelectionDialogIsOpen}
          receiverList={receiverList}
          handleSubmit={(receiver) => previewHandlers.previewCertificates(previewState.certificatesToPreview, receiver)}
          handleClose={previewHandlers.handleClosePreviewReceiverSelectionDialog}
        />
      }
      {
        downloadState.downloadReceiverSelectionDialogIsOpen &&
        <ReceiverSelectionDialog
          open={downloadState.downloadReceiverSelectionDialogIsOpen}
          receiverList={receiverList}
          handleSubmit={(receiver) => downloadHandlers.downloadCertificates(downloadState.selectedEmployeeCodesToDownload, receiver)}
          handleClose={downloadHandlers.handleCloseDownloadReceiverSelectionDialog}
        />
      }
    </CommonPage>
  );
}

export default Certificates;