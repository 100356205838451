import React from "react";
import { Tooltip } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';

const FileInput = ({ isCreate, handleChange }) => {

    return (
        <>
            <input
                type="file"
                id={isCreate ? "fileUpload" : "fileUploadEdit"}
                accept={'.jpg,.bmp,.png,.jpeg,.rar,.zip,.gz,.tar,.xls,.xlsx,.txt,.rtf,.doc,.docx,.pdf,.html,.ppt,.pptx'}
                style={{ display: "none" }}
                onChange={(e) => {
                    handleChange(e.target.files[0]);
                    e.target.value = '';
                }}
            />
            <label htmlFor={isCreate ? "fileUpload" : "fileUploadEdit"} style={{ display: 'flex' }}>
                <Tooltip title={'Si es necesario incluya un adjunto'}>
                    <DescriptionIcon style={{ cursor: 'pointer' }} />
                </Tooltip>
            </label>
        </>
    )
}

export default FileInput;