import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useHandleForm = (data, createCallback, invalidDataCallback, type, level) => {


  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {

      if (fieldName === 'attachment') {
        return null;
      }

      if (fieldName === "status") {
        return "";
      }

      return "";
    }

    const fieldNames = [
      'publicComment',
      'htmlText',
      'attachment',
      'privateComment',
      'status',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dispatch = useDispatch();

  const dataIsValid = () => {
    return Boolean(formData.publicComment);
  }

  const submit = () => {
    if (dataIsValid()) {
      if (!formData.status && level === "E") {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar un nuevo estado", severity: "error" }));
        return false;
      }

      let dataToSend = {
        ...formData,
        status: level === "M" ? data.status : formData.status,
        type: type,
        internalCode: data.internalCode
      };

      createCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'publicComment') {
      setFormData({
        ...formData,
        publicComment: value.text,
        htmlText: value.htmlText,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
