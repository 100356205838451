import React, { useState } from "react";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { deleteCentersSettingsAction, getCentersSettingsAction } from "@icarius-pages/centersSettings/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const { data, closeNew } = props;
  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteCentersSettingsAction(data))
        .then((res) => {
          if (res?.status === 200) {
            dispatch(getCentersSettingsAction());
          }
        });
    }
  }

  const handleClosePublication = () => {
    !isCreate && setIsOpen(prev => !prev);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getLocalizedString("deleteCenterQuestion"),
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: !isCreate ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={`Creación de centro de publicación`} />
                :
                <>
                  <DataContainer sm={12} md={3} lg={3} text={data.code} />
                  <DataContainer sm={12} md={9} lg={9} text={data.name} />
                </>
            }
          </Grid>

          <Grid container direction="row" item xs={1} justify="center">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
    </>
  );
}

export default DataItem;
