import React from "react";
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    DialogActions,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import TextEditor from "@icarius-common/textEditor";
import LinkDownloadPollItem from "@icarius-pages/feed/components/form/components/linkDownloadPollItem";
import { withStyles } from "@material-ui/core/styles";
import { answerCommunicationAction } from "@icarius-pages/communicationChannel/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import FileInput from "./components/fileInput";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75 };

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const Form = (props) => {

    const {
        level,
        statusList,
        type,
        data,
        classes,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        if (dataToSend.status === "S" || dataToSend.status === "R") {
            dispatch(openDialogAction(
                {
                    title: getLocalizedString("atention"),
                    msg: 'Al pasar la comunicación a los estados "Se deja sin efecto" o "Resuelta", ya no se permitirá realizar más acciones o crear nuevas respuestas',
                    onConfirm: () => dispatch(answerCommunicationAction(dataToSend, level))
                        .then((res) => {
                            if (res?.status === 200 && handleClose) {
                                handleClose();
                            }
                        })
                }
            ));
        } else {
            dispatch(answerCommunicationAction(dataToSend, level))
                .then((res) => {
                    if (res?.status === 200 && handleClose) {
                        handleClose();
                    }
                });
        }
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'Debe ingresar el comentario de la comunicación', severity: "error", duration: 10000 }));
    }

    const {
        isCreate,
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, create, openValidationError, type, level);

    const submitButtonText = (() => {
        if (!isCreate) return 'Guardar';
        return 'Enviar';
    })();


    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography variant="h6" className="whiteText" style={{ marginTop: -15 }}>
                    {`${data.typeString} ${data.name ? `de ${data.name}` : "anónima"} el ${data.date} a las ${data.time}`}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography className="whiteText">
                    {`Responsable asignado: ${data.responsibleName || "Sin responsable asignado"}`}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography className="whiteText">
                    {`Estado actual: ${data.statusString}`}
                </Typography>
            </div>
            {
                level === "E" &&
                <Grid container item alignItems="center" xs={12} style={gridStyle}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id={'label-reason'}>{"Nuevo estado de la comunicación"}</InputLabel>
                        <Select
                            disabled={["C", "R", "S"].includes(data.status)}
                            value={formData.status}
                            labelId={`label-status`}
                            id={`select-status`}
                            onChange={(e) => setFormValue(e.target.value, "status")}
                            margin={"none"}
                        >
                            {
                                statusList
                                    .filter(item => item.key !== data.status)
                                    .filter(item => level === "M" ? item.onlyForEmployers === false : true).map(item => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.key}
                                            value={item.key}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            }
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, marginTop: 10, }}>
                <Typography className="whiteText">
                    {"Comentario público de la acción: "}
                </Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 10, marginTop: 10, }}>
                <div style={{ flex: 1, minWidth: 200 }}>
                    <TextEditor
                        initialValue={''}
                        placeholder={'Ingrese comentario'}
                        suggestionOptions={[]}
                        handleChange={(text, htmlText) => setFormValue({ text, htmlText }, 'publicComment')}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, marginTop: 10, }}>
                <Typography className="whiteText">
                    {"Comentario de uso interno (Estos comentarios solo serán visualizados por Managers y Empleadores):"}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: 15,
                    marginTop: 10,
                    marginBottom: 10,
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                    margin={"none"}
                    value={formData.privateComment}
                    onChange={(e) => setFormValue(e.target.value, "privateComment")}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                    inputProps={{ spellCheck: 'false' }}
                />
            </div>
            {
                Boolean(formData.attachment) &&
                <div style={{ maxWidth: 'fit-content' }}>
                    <LinkDownloadPollItem
                        handleDelete={() => setFormValue(null, 'attachment')}
                        data={formData.attachment}
                        type='file'
                    />
                </div>
            }
            <DialogActions>
                <div style={{ marginRight: 10 }}>
                    <FileInput
                        isCreate={isCreate}
                        handleChange={(value) => setFormValue(value, 'attachment')}
                    />
                </div>
                <ButtonDialogAction onClick={submit} isAccept text={submitButtonText} />
            </DialogActions>
        </>
    )
}

export default withStyles(styles)(Form);