import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommunicationChannelAction,
  deleteCommunicationChannelAction,
  getShowComplaintsAction,
  clearDataFromStore,
} from "../actions";
import {
  getLoading,
  getData,
  getLoadingHome,
  getIndicators,
  getClassificationList,
} from "../selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { getUserData } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString, } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getIsAdmin } from "src/app/selectors";

const statusList = [
  { key: "A", value: "Asignada a un responsable", disabled: true, possibleStates: ["P", "I"], onlyForEmployers: true },
  { key: "I", value: "Recopilando información", disabled: false, possibleStates: ["P"], onlyForEmployers: false },
  { key: "P", value: "En progreso", disabled: false, possibleStates: ["D", "S", "R"], onlyForEmployers: false },
  { key: "D", value: "Momentáneamente detenida", disabled: false, possibleStates: ["P", "I", "S", "R"], onlyForEmployers: false },
  { key: "S", value: "Se deja sin efecto", disabled: false, possibleStates: [], onlyForEmployers: true },
  { key: "R", value: "Resuelta", disabled: false, possibleStates: [], onlyForEmployers: true }
]

const useCommunicationChannel = (pendingCommunications) => {

  const [showOnlyActiveRows, setShowOnlyActiveRows] = useState(true);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [assignResponsibleDialogIsOpen, setAssignResponsibleDialogIsOpen] = useState(false);
  const [actionCreateDialogIsOpen, setActionCreateDialogIsOpen] = useState(false);
  const [classificationDialogIsOpen, setClassificationDialogIsOpen] = useState(false);
  const [timelineIsOpen, setTimelineIsOpen] = useState(false);
  const [timelineData, setTimelineData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState("");

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const indicators = useSelector(getIndicators);
  const classificationList = useSelector(getClassificationList);
  const isLoading = useSelector(getLoading);
  const societySelected = useSelector(getSocietySelected);
  const showComplaints = true;
  const isLoadingHome = useSelector(getLoadingHome);
  const isAdmin = useSelector(getIsAdmin);
  const { level, code } = useSelector(getUserData);

  const filteredData = useMemo(() => {
    let auxData = [...data];

    //filtrar segun año seleccionado
    if (showOnlyActiveRows) {
      auxData = auxData.filter((item) => {
        return !["S", "R"].includes(item.status);
      })
    };

    return auxData;
  }, [data, showOnlyActiveRows]);

  useEffect(() => {
    if (level === "E" || (level === "M" && pendingCommunications)) {
      dispatch(getCommunicationChannelAction());
      return;
    }

    if (selectedChannel) {
      dispatch(getCommunicationChannelAction(selectedChannel));
      return;
    }

    if (!selectedChannel) {
      dispatch(getShowComplaintsAction());
      return;
    }
  }, [dispatch, level, selectedChannel, pendingCommunications])

  const handleSwitchOnlyActives = () => setShowOnlyActiveRows(prev => !prev);

  const handleDelete = (internalCode) => {
    dispatch(deleteCommunicationChannelAction({
      internalCode
    }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    if (!isAdmin && selectedRows[0]["status"] !== "C") {
      dispatch(openSnackbarAction({ msg: "No es posible eliminar la comunicación porque ya tiene un responsable asignado", severity: "warning" }));
      return;
    }

    const message = isAdmin ?
      <>
        Usted puede cambiar el estado de la comunicación a: "Resuelta" o bien "Dejarla sin efecto", y con ello quedará el registro histórico.
        <br />
        Si la elimina se perderá toda la información y trazabilidad de la comunicación.
        <br />
        ¿Desea eliminarla de todos modos?
      </>
      : '¿Está seguro que quiere eliminar la comunicación?';

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: message,
      onConfirm: () => handleDelete(selectedRows[0]["internalCode"]),
      maxWidth: 'md',
    }));
  }

  const handleOpenActionCreateDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    if (!selectedRows[0]["responsibleCode"]) {
      dispatch(openSnackbarAction({ msg: "La comunicación debe estar asignada a un responsable", severity: "warning" }));
      return;
    }

    if (isAdmin && selectedRows[0]["responsibleCode"] !== code) {
      dispatch(openSnackbarAction({
        msg: "Para crear una acción o respuesta usted debe ser el responsable a cargo de la atención. Si lo requiere, asígnese primero como el responsable.",
        duration: null,
        severity: "warning",
      }));
      return;
    }

    if (selectedRows[0]["status"] === "S" || selectedRows[0]["status"] === "R") {
      dispatch(openSnackbarAction({ msg: "La comunicación ya fue concluida", severity: "warning" }));
      return;
    }

    const newSelectedData = { ...selectedRows[0] };

    setActionCreateDialogIsOpen(true);
    setSelectedData(newSelectedData);
  }

  const handleCloseActionCreateDialog = () => {
    setActionCreateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenClassificationDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    if (!selectedRows[0]["responsibleCode"]) {
      dispatch(openSnackbarAction({ msg: "La comunicación debe estar asignada a un responsable", severity: "warning" }));
      return;
    }

    if (isAdmin && selectedRows[0]["responsibleCode"] !== code) {
      dispatch(openSnackbarAction({
        msg: "Para clasificar la comunicación usted debe ser el responsable a cargo de la atención. Si lo requiere, asígnese primero como el responsable.",
        duration: null,
        severity: "warning",
      }));
      return;
    }

    setClassificationDialogIsOpen(true);
    setSelectedData(selectedRows[0]);
  }

  const handleCloseClassificationDialog = () => {
    setClassificationDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenCreateDialog = () => {
    setCreateDialogIsOpen(true);
  }

  const handleOpenAssignResponsibleDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    if (["S", "R"].includes(selectedRows[0]["status"])) {
      dispatch(openSnackbarAction({ msg: "La comunicación ya fue concluida", severity: "warning" }));
      return;
    }

    if (!isAdmin && selectedRows[0]["responsibleCode"] && selectedRows[0]["responsibleCode"] !== code) {
      dispatch(openSnackbarAction({ msg: "La comunicación ya está asignada a otro empleador", severity: "warning" }));
      return;
    }

    const newSelectedData = { ...selectedRows[0] };

    setAssignResponsibleDialogIsOpen(true);
    setSelectedData(newSelectedData);
  }

  const handleCloseAssignResponsibleDialog = () => {
    setAssignResponsibleDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    if (selectedRows[0]["status"] !== "C") {
      dispatch(openSnackbarAction({ msg: "No es posible editar la comunicación porque ya tiene un responsable asignado", severity: "warning" }));
      return;
    }

    const newSelectedData = { ...selectedRows[0] };

    setCreateDialogIsOpen(true);
    setSelectedData(newSelectedData);
  }

  const handleOpenTimelineAgGrid = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un unico item", severity: "warning" }));
      return;
    }

    setTimelineData(selectedRows[0]);
    setTimelineIsOpen(true);
  }

  const handleOpenTimeline = (data) => {
    setTimelineData(data);
    setTimelineIsOpen(true);
  }

  const handleCloseTimeline = () => {
    setTimelineIsOpen(false);
    setTimelineData(null);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleSelectCommunication = (type) => {
    setSelectedChannel(type);
  }

  const handleUnselectCommunication = () => {
    setSelectedChannel("")
    dispatch(clearDataFromStore());
    setShowOnlyActiveRows(true);
  }

  const state = {
    assignResponsibleDialogIsOpen,
    selectedChannel,
    actionCreateDialogIsOpen,
    classificationDialogIsOpen,
    createDialogIsOpen,
    timelineIsOpen,
    timelineData,
    societySelected,
    showOnlyActiveRows,
    selectedData,
  }

  const handlers = {
    handleSelectCommunication,
    handleUnselectCommunication,
    handleOpenCreateDialog,
    handleCloseCreateEditDialog,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
    handleOpenTimeline,
    handleCloseTimeline,
    handleOpenActionCreateDialog,
    handleCloseActionCreateDialog,
    handleOpenAssignResponsibleDialog,
    handleOpenTimelineAgGrid,
    handleCloseAssignResponsibleDialog,
    handleOpenClassificationDialog,
    handleCloseClassificationDialog,
    handleSwitchOnlyActives,
  }

  return {
    showComplaints,
    indicators,
    statusList,
    classificationList,
    level,
    data: filteredData,
    isLoading,
    isLoadingHome,
    state,
    handlers,
    isAdmin,
  }
}

export default useCommunicationChannel;