import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, type) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {

      if (fieldName === 'attachment') {
        return isCreate ? null : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'comment',
      'attachment',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    return Boolean(formData.comment);
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      if (!isCreate) {
        dataToSend.updateAttachment = !(Boolean(data.attachment && data.attachment === formData.attachment));
        dataToSend.attachment = data.attachment === formData.attachment ? null : formData.attachment;
      }

      isCreate ? createCallback({ ...dataToSend, type: type }) : modifyCallback({ internalCode: data.internalCode, ...dataToSend });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
