import { getLocalizedString } from "@icarius-localization/strings";
import { fieldTypes, TABS } from "@icarius-utils/constants";

const NAME = "CERTIFICATES_PAGE";

export const certificatesEmployeeFields = [
  {
    name: "CODIGO DE EMPLEADO",
    type: fieldTypes.text,
    label: getLocalizedString("employeeCode"),
    tab: TABS.personal,
  },
  {
    name: "PERSONAL DE PLANTA",
    type: fieldTypes.text,
    label: getLocalizedString("plantPersonnel"),
    tab: TABS.personal,
  },
  {
    name: "NRO INTERNO",
    type: fieldTypes.text,
    label: getLocalizedString("internalNumber"),
    tab: TABS.personal,
  },
  {
    name: "EMAIL",
    type: fieldTypes.email,
    label: getLocalizedString("email"),
    tab: TABS.personal,
  },  
  {
    name: "ESTADO CIVIL",
    type: fieldTypes.text,
    label: getLocalizedString("civilState"),
    tab: TABS.personal,
  },
  {
    name: "SEXO",
    type: fieldTypes.text,
    label: getLocalizedString("sex"),
    tab: TABS.personal,
  },
  {
    name: "NACIONALIDAD",
    type: fieldTypes.text,
    label: getLocalizedString("nationality"),
    tab: TABS.personal,
  },
  
  {
    name: "FECHA DE NACIMIENTO",
    type: fieldTypes.text,
    label: getLocalizedString("bornDate"),
    tab: TABS.personal,
  },
  {
    name: "CELULAR",
    type: fieldTypes.phone,
    label: getLocalizedString("cellphone"),
    tab: TABS.personal,
  },
  {
    name: "DIRECCION",
    type: fieldTypes.address,
    label: getLocalizedString("address"),
    tab: TABS.personal,
  },
  
  {
    name: "PAIS",
    type: fieldTypes.text,
    label: getLocalizedString("country"),
    tab: TABS.personal,
  },
  {
    name: "REGION",
    type: fieldTypes.text,
    label: getLocalizedString("region"),
    tab: TABS.personal,
  },
  {
    name: "PROVINCIA",
    type: fieldTypes.text,
    label: getLocalizedString("provicia"),
    tab: TABS.personal,
  },
  {
    name: "COMUNA",
    type: fieldTypes.text,
    label: getLocalizedString("comuna"),
    tab: TABS.personal,
  },
  {
    name: "CODIGO POSTAL",
    type: fieldTypes.text,
    label: getLocalizedString("postalCode"),
    tab: TABS.personal,
  },  
  {
    name: "TIPO DE FUNCIONARIO",
    type: fieldTypes.text,
    label: getLocalizedString("fType"),
    tab: TABS.contract,
  },
  {
    name: "CARGO",
    type: fieldTypes.text,
    label: getLocalizedString("position"),
    tab: TABS.contract,
  },
  {
    name: "FECHA ASIGNACION CARGO",
    type: fieldTypes.text,
    label: getLocalizedString("positionDate"),
    tab: TABS.contract,
  },
  {
    name: "FUNCION",
    type: fieldTypes.text,
    label: getLocalizedString("function"),
    tab: TABS.contract,
  },
  {
    name: "FECHA ASIGNACION FUNCION",
    type: fieldTypes.text,
    label: getLocalizedString("functionDate"),
    tab: TABS.contract,
  },
  {
    name: "CATEGORIA",
    type: fieldTypes.text,
    label: getLocalizedString("category"),
    tab: TABS.contract,
  },
  {
    name: "ESPECIALIDAD",
    type: fieldTypes.text,
    label: getLocalizedString("specialty"),
    tab: TABS.contract,
  },
  {
    name: "CONTRATISTA",
    type: fieldTypes.text,
    label: getLocalizedString("contratista"),
    tab: TABS.contract,
  },
  {
    name: "TIPO DE CONTRATO",
    type: fieldTypes.text,
    label: getLocalizedString("contractType"),
    tab: TABS.contract,
  },
  {
    name: "FECHA DE INGRESO",
    type: fieldTypes.text,
    label: getLocalizedString("incomeDate"),
    tab: TABS.contract,
  },
  {
    name: "FECHA DE EGRESO",
    type: fieldTypes.text,
    label: getLocalizedString("outcomeDate"),
    tab: TABS.contract,
  },  
  {
    name: "TIPO DE JORNADA",
    type: fieldTypes.text,
    label: getLocalizedString("jornadaType"),
    tab: TABS.contract,
  },
  {
    name: "HORAS DIARIAS CONTRATO",
    type: fieldTypes.text,
    label: getLocalizedString("hoursPerDay"),
    tab: TABS.contract,
  },
  {
    name: "HORAS SEMANALES CONTRATO",
    type: fieldTypes.text,
    label: 'Horas semanales contrato',
    tab: TABS.contract,
  },
  {
    name: "HORAS MENSUALES CONTRATO",
    type: fieldTypes.text,
    label: getLocalizedString("hoursPerMonth"),
    tab: TABS.contract,
  },
  {
    name: "LUGAR DE PAGO",
    type: fieldTypes.text,
    label: getLocalizedString("paymentLocation"),
    tab: TABS.location,
  },
  {
    name: "LUGAR DE TRABAJO",
    type: fieldTypes.text,
    label: getLocalizedString("jobLocation"),
    tab: TABS.location,
  },
  {
    name: "GERENCIA",
    type: fieldTypes.text,
    label: getLocalizedString("management"),
    tab: TABS.location,
  },
  {
    name: "DEPARTAMENTO",
    type: fieldTypes.text,
    label: getLocalizedString("department"),
    tab: TABS.location,
  },
  {
    name: "SECCION",
    type: fieldTypes.text,
    label: getLocalizedString("section"),
    tab: TABS.location,
  },
  {
    name: "FORMA DE PAGO",
    type: fieldTypes.text,
    label: getLocalizedString("paymentMethod"),
    tab: TABS.remuneration,
  },
  {
    name: "BANCO",
    type: fieldTypes.text,
    label: getLocalizedString("bank"),
    tab: TABS.remuneration,
  },
  {
    name: "CUENTA BANCARIA",
    type: fieldTypes.text,
    label: getLocalizedString("bankAccount"),
    tab: TABS.remuneration,
  },
  {
    name: "TIPO DE CUENTA BANCARIA",
    type: fieldTypes.text,
    label: getLocalizedString("bankAccountType"),
    tab: TABS.remuneration,
  },
  {
    name: "UNIDAD SALARIAL",
    type: fieldTypes.text,
    label: getLocalizedString("salaryUnit"),
    tab: TABS.remuneration,
  },
  {
    name: "MONEDA",
    type: fieldTypes.text,
    label: getLocalizedString("coin"),
    tab: TABS.remuneration,
  },
  {
    name: "SUELDO BASICO",
    type: fieldTypes.text,
    label: getLocalizedString("salary"),
    tab: TABS.remuneration,
  },
  {
    name: "SINDICATO",
    type: fieldTypes.text,
    label: getLocalizedString("laborUnion"),
    tab: TABS.remuneration,
  },
  {
    name: "JUBILADO",
    type: fieldTypes.text,
    label: getLocalizedString("retired"),
    tab: TABS.remuneration,
  },
  {
    name: "TURNO",
    type: fieldTypes.text,
    label: getLocalizedString("shift"),
    tab: TABS.time,
  },
  {
    name: "FECHA ASIGNACION TURNO",
    type: fieldTypes.text,
    label: getLocalizedString("shiftDate"),
    tab: TABS.time,
  },
];

export default NAME;
