import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const ReceiverSelectionDialog = (props) => {

  const {
    open,
    receiverList,
    handleSubmit,
    handleClose,
  } = props;

  const [receiver, setReceiver] = useState("");

  const dispatch = useDispatch();

  const submit = () => {
    if (!receiver) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un destinatario", severity: "error" }));
      return;
    }

    handleSubmit(receiver);
    handleClose();
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {'Selección de destinatario'}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-receiver`}>
                  {"Destinatario"}
                </InputLabel>
                <Select
                  value={receiver}
                  labelId={`label-receiver`}
                  id={`receiver`}
                  onChange={(e) => setReceiver(e.target.value)}
                  margin={"none"}
                >
                  {
                    receiverList?.map((item) => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={"Cancelar"} />
          <ButtonDialogAction onClick={submit} isAccept text={"Aceptar"} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ReceiverSelectionDialog;