import NAME from "./constants";

export const GET_CENTERS_SETTINGS = NAME + "/GET_CENTERS_SETTINGS";
export const GET_CENTERS_SETTINGS_FULFILLED = NAME + "/GET_CENTERS_SETTINGS_FULFILLED";
export const GET_CENTERS_SETTINGS_REJECTED = NAME + "/GET_CENTERS_SETTINGS_REJECTED";

export const CREATE_CENTERS_SETTINGS = NAME + "/CREATE_CENTERS_SETTINGS";
export const CREATE_CENTERS_SETTINGS_FULFILLED = NAME + "/CREATE_CENTERS_SETTINGS_FULFILLED";
export const CREATE_CENTERS_SETTINGS_REJECTED = NAME + "/CREATE_CENTERS_SETTINGS_REJECTED";

export const UPDATE_CENTERS_SETTINGS = NAME + "/UPDATE_CENTERS_SETTINGS";
export const UPDATE_CENTERS_SETTINGS_FULFILLED = NAME + "/UPDATE_CENTERS_SETTINGS_FULFILLED";
export const UPDATE_CENTERS_SETTINGS_REJECTED = NAME + "/UPDATE_CENTERS_SETTINGS_REJECTED";

export const DELETE_CENTERS_SETTINGS = NAME + "/DELETE_CENTERS_SETTINGS";
export const DELETE_CENTERS_SETTINGS_FULFILLED = NAME + "/DELETE_CENTERS_SETTINGS_FULFILLED";
export const DELETE_CENTERS_SETTINGS_REJECTED = NAME + "/DELETE_CENTERS_SETTINGS_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";

