import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import Loader from "@icarius-common/loader";
import DialogTransition from "@icarius-common/dialogTransition";
import Form from "./form";

const ClassificationDialog = (props) => {

    const {
        open,
        data,
        classificationList,
        isLoading,
        handleClose,
    } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {"Clasificación de la comunicación"}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Loader open={isLoading} />
                <Form
                    data={data}
                    classificationList={classificationList}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ClassificationDialog;