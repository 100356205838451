import React from "react";
import {
    DialogActions,
    Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import TextEditor from "@icarius-common/textEditor";
import LinkDownloadPollItem from "@icarius-pages/feed/components/form/components/linkDownloadPollItem";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createCommunicationChannelAction, updateCommunicationChannelAction } from "@icarius-pages/communicationChannel/actions";
import FileInput from "./components/fileInput";
import useHandleForm from "./useHandleForm";

const Form = (props) => {

    const {
        type,
        typeString,
        data,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        dispatch(createCommunicationChannelAction(dataToSend))
            .then((res) => {
                if (res?.status === 200 && handleClose) {
                    dispatch(openSnackbarAction({
                        msg: type !== "D" ?
                            `Gracias por compartir tu ${typeString} con nosotros. A la brevedad asignaremos a un encargado para su atención.` :
                            "Gracias por compartir tu denuncia con nosotros. A la brevedad asignaremos a un encargado para su atención y te garantizamos total confidencialidad.",
                        severity: "success",
                        duration: null
                    }));
                    handleClose();
                }
            });
    }

    const update = (dataToSend) => {
        dispatch(updateCommunicationChannelAction(dataToSend))
            .then((res) => {
                if (res?.status === 200 && handleClose) {
                    dispatch(openSnackbarAction({ msg: `Tu ${typeString} fue modificada exitósamente`, severity: "success", duration: 10000 }));
                    handleClose();
                }
            });
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'Debe ingresar el comentario de la comunicación', severity: "error", duration: 10000 }));
    }

    const {
        isCreate,
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, create, update, openValidationError, type);

    const submitButtonText = (() => {
        if (!isCreate) return 'Guardar';
        return 'Enviar';
    })();

    return (
        <>
            <>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                    <Typography className="whiteText">
                        {`Comentario relacionado con su ${typeString}: `}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 10, marginTop: 10, }}>
                    <div style={{ flex: 1, minWidth: 200 }}>
                        <TextEditor
                            initialValue={isCreate ? '' : data.comment}
                            placeholder={'Ingrese comentario'}
                            suggestionOptions={[]}
                            handleChange={(value) => setFormValue(value, 'comment')}
                        />
                    </div>
                </div>
            </>
            {
                Boolean(formData.attachment) &&
                <div style={{ maxWidth: 'fit-content' }}>
                    <LinkDownloadPollItem
                        handleDelete={() => setFormValue(null, 'attachment')}
                        data={formData.attachment}
                        type='file'
                    />
                </div>
            }
            <DialogActions>
                <div style={{ marginRight: 10 }}>
                    <FileInput
                        isCreate={isCreate}
                        handleChange={(value) => setFormValue(value, 'attachment')}
                    />
                </div>
                <ButtonDialogAction onClick={submit} isAccept text={submitButtonText} />
            </DialogActions>
        </>
    )
}

export default Form;