import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  indicators: {},
  classificationList: [],
  isLoadingHome: false,
  isLoading: false,
  showComplaints: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        showComplaints: action.payload.showComplaints,
        indicators: action.payload.indicators,
        classificationList: action.payload.classificationList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SHOW_COMPLAINTS:
      return { ...state, isLoadingHome: true };
    case actionTypes.SHOW_COMPLAINTS_FULFILLED:
      return {
        ...state,
        isLoadingHome: false,
        showComplaints: action.payload.showComplaints,
        indicators: action.payload.indicators,
      };
    case actionTypes.SHOW_COMPLAINTS_REJECTED:
      return { ...state, isLoadingHome: false };


    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.UPDATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.UPDATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };
      
    case actionTypes.CLASSIFY:
      return { ...state, isLoading: true };
    case actionTypes.CLASSIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CLASSIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.ASSIGN_RESPONSIBLE:
      return { ...state, isLoading: true };
    case actionTypes.ASSIGN_RESPONSIBLE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.ASSIGN_RESPONSIBLE_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.ANSWER_COMMUNICATION:
      return { ...state, isLoading: true };
    case actionTypes.ANSWER_COMMUNICATION_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.ANSWER_COMMUNICATION_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.RESET_DATA:
      return { ...state, data: [] };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
