import React from "react";
import { useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { getAppColor } from "src/app/selectors";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import ProfileImg from "@icarius-common/profileImg";
import 'react-vertical-timeline-component/style.min.css';
import moment from "moment";
import { DownloadIcon } from "@icarius-icons";
import { Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import TextEditor from "@icarius-common/textEditor";

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const Timeline = (props) => {
    const {
        level,
        data,
        handleGoBack,
    } = props;

    const color = useSelector(getAppColor);

    const goBackButton = () => (
        <CustomIconButton
            title={'Volver'}
            onClick={handleGoBack}
            type={"goBack"}
        />
    )

    const downloadAttachment = (attachment) => {
        previewFromURL(RESOURCES_ENDPOINT + attachment);
    }

    const date = createDateFromDDMMYYYY(data.date);
    const month = date.getMonth();

    return (
        <CommonPage
            isNotGridPage
            title={'Línea de tiempo'}
            menuItems={[goBackButton]}

        >
            <VerticalTimeline lineColor={color} layout={'2-columns'}>
                <VerticalTimelineElement
                    position={'left'}
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--medalTableHeader)', color: 'var(--mainText)', boxShadow: 'none' }}
                    contentArrowStyle={{ borderRight: '7px solid  var(--medalTableHeader)' }}
                    iconStyle={{ background: color }}
                    date={`${monthNames[month]} - ${formatDate(date)} - ${data.time}`}
                >
                    <div style={{ marginBottom: 10 }}>
                        {
                            data.name && <p style={{ margin: 0, fontWeight: 300 }}>
                                {"Creada por: "}<span style={{ fontWeight: 700 }}>{data.name}</span>
                            </p>
                        }
                        <p style={{ margin: 0, fontWeight: 300 }}>
                            {`Responsable de atención: ${data.responsibleName || "Sin responsable asignado"}`}
                        </p>
                    </div>
                    <h3 className="vertical-timeline-element-title">
                        {data.typeString}
                    </h3>
                    <h3 className="vertical-timeline-element-title" style={{ color: color }}>
                        {data.statusString}
                    </h3>
                    <TextEditor
                        initialValue={data.comment}
                        readOnly
                    />
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ margin: 0, fontWeight: 300 }}>
                            {`${data.status === "S" || data.status === "R" ? "Días totales que tomó la atención" : "Días transcurridos desde el ingreso"}: ${data.status === "S" || data.status === "R" ? moment(createDateFromDDMMYYYY(data.endDate)).diff(moment(date), 'days') : moment().diff(moment(date), 'days')}`}
                        </p>
                    </div>
                    {
                        Boolean(data.attachment) &&
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "transparent",
                            }}
                        >
                            <div className={"download-icon-container"}>
                                <DownloadIcon className={"download-icon"} />
                            </div>
                            <Typography
                                onClick={() => downloadAttachment(data.attachment)}
                                className="whiteText"
                                style={{
                                    cursor: "pointer",
                                    fontWeight: 400,
                                    textDecoration: "underline",
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                    maxWidth: "80%",
                                    margin: "0px 0px 0px 10px"
                                }}
                            >
                                {getLocalizedString("downloadAttachment")}
                            </Typography>
                        </div>
                    }
                </VerticalTimelineElement>
                {
                    data.details.map((item, index) => {
                        const date = createDateFromDDMMYYYY(item.date);
                        const month = date.getMonth();

                        return (
                            <VerticalTimelineElement
                                key={index}
                                position={'right'}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'var(--medalTableHeader)', color: 'var(--mainText)', boxShadow: 'none' }}
                                contentArrowStyle={{ borderRight: '7px solid  var(--medalTableHeader)' }}
                                iconStyle={{ background: color }}
                                date={`${monthNames[month]} - ${formatDate(date)} - ${item.time}`}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <ProfileImg
                                        size={30}
                                        image={item.img}
                                    />
                                    <h3 className="vertical-timeline-element-title" style={{ color }}>
                                        {item.responsibleName}
                                    </h3>
                                </div>
                                <div style={{ marginBottom: 5 }}>
                                    <TextEditor
                                        initialValue={item.comment}
                                        readOnly
                                    />
                                </div>
                                {
                                    level !== "C" && item.privateComment &&
                                    <>
                                        <h3 className="vertical-timeline-element-title" style={{ color: color }}>
                                            {"Para uso interno:"}
                                        </h3>
                                        <p style={{ margin: 0, fontWeight: 300, whiteSpace: 'pre-line' }}>
                                            {item.privateComment}
                                        </p>
                                    </>
                                }
                                {
                                    Boolean(item.attachment) &&
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className={"download-icon-container"}>
                                            <DownloadIcon className={"download-icon"} />
                                        </div>
                                        <Typography
                                            onClick={() => downloadAttachment(item.attachment)}
                                            className="whiteText"
                                            style={{
                                                cursor: "pointer",
                                                fontWeight: 400,
                                                textDecoration: "underline",
                                                textAlign: "center",
                                                wordBreak: "break-word",
                                                maxWidth: "80%",
                                                margin: "0px 0px 0px 10px"
                                            }}
                                        >
                                            {getLocalizedString("downloadAttachment")}
                                        </Typography>
                                    </div>
                                }
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </CommonPage>
    );
}

export default Timeline;