import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import SuggestionIcon from "./suggestionIcon";
import QuestionsIcon from "./questionsIcon";
import ComplaintsIcon from "./complaintsIcon";
import ReportsIcon from "./reportsIcon";

const HomeScreen = ({ indicators, isLoading, handleSelectCommunication, showComplaints }) => {
    return (
        <CommonPage
            isNotGridPage
            isLoading={isLoading}
        >
            <Grid container item xs={12} style={{ padding: "0px 20px", marginTop: 25 }}>
                <Grid item xs={12}>
                    <Typography className="whiteText" variant="h5">
                        {"¡Estás en nuestro canal de comunicaciones, bienvenido!"}
                    </Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 20 }}>
                    <Typography className="whiteText" variant="subtitle1">
                        {showComplaints ?
                            "Podrás enviarnos tus consultas, hacer reclamos, realizar aportes o sugerir mejoras y canalizar denuncias." :
                            "Podrás enviarnos tus consultas, hacer reclamos, realizar aportes y sugerir mejoras."}
                    </Typography>
                </Grid>
                <Grid container item xs={12} style={{ display: "flex", justifyContent: "center", margin: 50 }}>
                    <Grid item xs={12} sm={6} md={2} style={{ cursor: "pointer", display: "flex", position: "relative", flexDirection: "column", alignItems: "center" }}
                        onClick={() => handleSelectCommunication("C")}>
                        <QuestionsIcon />
                        {
                            parseInt(indicators?.pendingQueries) > 0 &&
                            <div className="easyAccessCounter">
                                <Typography style={{ color: 'var(--secondaryBackgroundColor)', fontSize: "14px", fontWeight: "500" }}>
                                    {indicators?.pendingQueries > 9 ? "+9" : indicators?.pendingQueries}
                                </Typography>
                            </div>
                        }
                        <Typography className="whiteText" variant="subtitle1" style={{ fontWeight: 700, fontSize: 21 }}>
                            {`Consultas (${indicators?.totalQueries || 0})`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} style={{ cursor: "pointer", display: "flex", flexDirection: "column", position: "relative", alignItems: "center" }}
                        onClick={() => handleSelectCommunication("R")}>
                        <ComplaintsIcon />
                        {
                            parseInt(indicators?.pendingClaims) > 0 &&
                            <div className="easyAccessCounter">
                                <Typography style={{ color: 'var(--secondaryBackgroundColor)', fontSize: "14px", fontWeight: "500" }}>
                                    {indicators?.pendingClaims > 9 ? "+9" : indicators?.pendingClaims}
                                </Typography>
                            </div>
                        }
                        <Typography className="whiteText" variant="subtitle1" style={{ fontWeight: 700, fontSize: 21 }}>
                            {`Reclamos (${indicators?.totalClaims || 0})`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} style={{ cursor: "pointer", display: "flex", flexDirection: "column", position: "relative", alignItems: "center" }}
                        onClick={() => handleSelectCommunication("S")}>
                        <SuggestionIcon />
                        {
                            parseInt(indicators?.pendingSuggestions) > 0 &&
                            <div className="easyAccessCounter">
                                <Typography style={{ color: 'var(--secondaryBackgroundColor)', fontSize: "14px", fontWeight: "500" }}>
                                    {indicators?.pendingSuggestions > 9 ? "+9" : indicators?.pendingSuggestions}
                                </Typography>
                            </div>
                        }
                        <Typography className="whiteText" variant="subtitle1" style={{ fontWeight: 700, fontSize: 21 }}>
                            {`Sugerencias (${indicators?.totalSuggestions || 0})`}
                        </Typography>
                    </Grid>
                    {
                        showComplaints &&
                        <Grid item xs={12} sm={6} md={2} style={{ cursor: "pointer", display: "flex", flexDirection: "column", position: "relative", alignItems: "center" }}
                            onClick={() => handleSelectCommunication("D")}>
                            <ReportsIcon />
                            {
                                parseInt(indicators?.pendingComplaints) > 0 &&
                                <div className="easyAccessCounter">
                                    <Typography style={{ color: 'var(--secondaryBackgroundColor)', fontSize: "14px", fontWeight: "500" }}>
                                        {indicators?.pendingComplaints > 9 ? "+9" : indicators?.pendingComplaints}
                                    </Typography>
                                </div>
                            }
                            <Typography className="whiteText" variant="subtitle1" style={{ fontWeight: 700, fontSize: 21 }}>
                                {`Denuncias (${indicators?.totalComplaints || 0})`}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid container item xs={12}>
                    {
                        showComplaints && <Grid item xs={12}>
                            <Typography className="whiteText" variant="subtitle1">
                                {`Considera que cuando realices una `}<span style={{ color: "red" }}>"Denuncia"</span>{`, asignaremos a un encargado/a para atenderla de inmediato y tomaremos las medidas correctivas que sean necesarias a la brevedad.`}
                            </Typography>
                        </Grid>
                    }
                    {
                        showComplaints && <Grid item xs={12} style={{ margin: "30px 0px" }}>
                            <Typography className="whiteText" variant="subtitle1">
                                Cuando hagas una denuncia incluye la mayor cantidad de datos posibles, tales como: Fechas, horarios, relato de los hechos, lugares o nombres de personas si es necesario, porque eso nos permitirá analizar en detalle la denuncia realizada.
                            </Typography>
                        </Grid>
                    }
                    {
                        showComplaints && <Grid item xs={12}>
                            <Typography className="whiteText" variant="subtitle1">
                                Recuerda que, en caso de denuncias, requerimos la mayor información posible para ayudarte.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} style={{ margin: "30px 0px" }}>
                        <Typography className="whiteText" variant="subtitle1" style={{ textAlign: "end", fontWeight: 700 }}>
                            Escucharte, es parte de nuestro compromiso.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </CommonPage>
    )
}

export default HomeScreen;