import React from "react";
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { classifyCommunicationChannelAction } from "@icarius-pages/communicationChannel/actions";
import useHandleForm from "./useHandleForm";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import TextEditor from "@icarius-common/textEditor";

const gridStyle = { height: 75 };

const Form = (props) => {

    const {
        data,
        classificationList,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        dispatch(classifyCommunicationChannelAction(dataToSend))
            .then((res) => {
                if (res?.status === 200) {
                    handleClose();
                }
            });
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'Debe ingresar la clasificación', severity: "error", duration: 10000 }));
    }

    const {
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, create, openValidationError);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography variant="h6" className="whiteText" style={{ marginTop: -15 }}>
                    {`${data.typeString} ${data.name ? `de "${data.name}"` : "anónima"} el ${data.date} a las ${data.time}`}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography className="whiteText">
                    {`Responsable asignado: ${data.responsibleName || "Sin responsable asignado"}`}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Typography className="whiteText">
                    {`Estado actual: ${data.statusString}`}
                </Typography>
            </div>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id={'label-reason'}>{'Clasificación'}</InputLabel>
                    <Select
                        value={formData.classification}
                        labelId={'label-classification'}
                        id={'select-classification'}
                        onChange={(e) => setFormValue(e.target.value, "classification")}
                        margin={"none"}
                        displayEmpty
                    >
                        <MenuItem value={''}>
                            {'Sin clasificación'}
                        </MenuItem>
                        {
                            classificationList?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}
                                >
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Typography className="whiteText" variant="caption">
                {'Comentario:'}
            </Typography>
            <div style={{ marginBottom: 10 }}>
                <TextEditor
                    initialValue={data.comment}
                    readOnly
                />
            </div>
            <DialogActions>
                <ButtonDialogAction onClick={submit} isAccept text={'Guardar'} />
            </DialogActions>
        </>
    )
}

export default Form;
