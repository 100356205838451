import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import Loader from "@icarius-common/loader";
import DialogTransition from "@icarius-common/dialogTransition";
import Form from "./form";

const ActionDialog = (props) => {

    const {
        open,
        data,
        type,
        isLoading,
        statusList,
        level,
        handleClose,
    } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {"Crear nueva acción o respuesta"}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Loader open={isLoading} />
                <Form
                    level={level}
                    statusList={statusList}
                    type={type}
                    data={data}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ActionDialog;