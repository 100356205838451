import React from "react";

const ComplaintsIcon = (props) => (
    <svg {...props} width="112" height="96" viewBox="0 0 112 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7226_120)">
            <rect x="17.5" y="9.5" width="78" height="78" rx="39" fill="#FF592C" />
            <rect x="17.5" y="9.5" width="78" height="78" rx="39" stroke="var(--svgColor)" strokeWidth="3" />
            <path d="M60.5859 28.818L59.7432 54.636H53.6143L52.746 28.818H60.5859ZM52.5417 62.6291C52.5417 61.5395 52.9163 60.6372 53.6654 59.9222C54.4315 59.1901 55.436 58.8241 56.6788 58.8241C57.9386 58.8241 58.9431 59.1901 59.6921 59.9222C60.4412 60.6372 60.8158 61.5395 60.8158 62.6291C60.8158 63.6846 60.4412 64.5784 59.6921 65.3105C58.9431 66.0426 57.9386 66.4086 56.6788 66.4086C55.436 66.4086 54.4315 66.0426 53.6654 65.3105C52.9163 64.5784 52.5417 63.6846 52.5417 62.6291Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_7226_120">
                <rect x="111.656" y="0.772461" width="95" height="111" rx="2" transform="rotate(90 111.656 0.772461)" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ComplaintsIcon;
