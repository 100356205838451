import { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  downloadCertificateAction,
  signAndDownloadAction,
} from "../actions";

const useCertificatesDownload = (certificateList, collaborators, isDigitalSignVersion, selectedCertificate) => {

  const [selectedEmployeeCodesToDownload, setSelectedEmployeeCodesToDownload] = useState(null);
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [documentsToSign, setDocumentsToSign] = useState(null);
  const [downloadReceiverSelectionDialogIsOpen, setDownloadReceiverSelectionDialogIsOpen] = useState(false);
  const [pinDialogOpen, setPinDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleCloseDownloadReceiverSelectionDialog = () => {
    setDownloadReceiverSelectionDialogIsOpen(false);
  }

  const resetDownloadState = () => {
    setSelectedEmployeeCodesToDownload(null);
    setSelectedReceiver(null);
    setDocumentsToSign(null);
    setDownloadReceiverSelectionDialogIsOpen(false);
    setPinDialogOpen(false);
  }

  const handleValidateAndDownloadCertificatesByGrid = (gridRef) => {
    resetDownloadState();

    let auxSelectedCodEmp = gridRef.api.getSelectedRows().map((item) => item["CODIGO DE EMPLEADO"]);

    if (auxSelectedCodEmp.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    if (selectedCertificate === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCertificateType"), severity: "warning" }));
      return;
    }

    handleDownloadCertificates(auxSelectedCodEmp);
  };

  const handleValidateAndDownloadCertificatesByUserDialog = (auxSelectedCodEmp) => {
    resetDownloadState();

    if (selectedCertificate === "") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("selectCertificateType"), severity: "warning" }));
      return;
    }

    handleDownloadCertificates(auxSelectedCodEmp);
  };

  const handleDownloadCertificates = (auxSelectedCodEmp) => {
    const certificateObject = certificateList.find((item) => {
      return item.reference === selectedCertificate || item.code === selectedCertificate
    });

    if (certificateObject?.requiresReceiverSelection) {
      setSelectedEmployeeCodesToDownload(auxSelectedCodEmp);
      setDownloadReceiverSelectionDialogIsOpen(true);
    } else {
      downloadCertificates(auxSelectedCodEmp);
    }
  }

  const downloadCertificates = (selectedCollaborators, receiver) => {
    if (isDigitalSignVersion) {
      handleSetDataToSign(selectedCollaborators, receiver);
      return;
    }

    dispatch(downloadCertificateAction(selectedCertificate, selectedCollaborators, receiver));
  };

  const handleSetDataToSign = (selectedCollaboratorCodes, receiver) => {
    let auxDocumentsToSign = [];
    let amountInvalidMail = 0;

    selectedCollaboratorCodes.forEach((collaboratorCode) => {
      let collaborator = collaborators.find((item) => item["CODIGO DE EMPLEADO"] === collaboratorCode);

      if (collaborator) {
        if (collaborator["EMAIL"] === "-") {
          amountInvalidMail++;
        } else {
          auxDocumentsToSign.push(collaborator.dataDoc);
        }
      }
    })

    if (amountInvalidMail > 0) {
      dispatch(openSnackbarAction({
        msg: getLocalizedErrorString("certificateNoMail").replace("{amount}", auxDocumentsToSign.length).replace("{total}", selectedCollaboratorCodes.length),
        severity: "info",
        duration: 10000,
      }));
    }

    setSelectedReceiver(receiver);
    setDocumentsToSign(auxDocumentsToSign)
    setPinDialogOpen(true);
  }

  const handleSign = (password, nextSigner) => {
    let dataToSend = nextSigner ?
      documentsToSign?.map((item) => {
        return ({
          ...item,
          approvants: [...item.approvants, nextSigner],
        })
      })
      : documentsToSign;

    dispatch(signAndDownloadAction(dataToSend, password, selectedReceiver));
  }

  const handleClosePinDialog = () => {
    setPinDialogOpen(false)
  }

  const state = {
    pinDialogOpen,
    documentsToSign,
    downloadReceiverSelectionDialogIsOpen,
    selectedEmployeeCodesToDownload,
  }

  const handlers = {
    handleSign,
    handleValidateAndDownloadCertificatesByGrid,
    handleValidateAndDownloadCertificatesByUserDialog,
    downloadCertificates,
    handleClosePinDialog,
    handleCloseDownloadReceiverSelectionDialog,
  }

  return {
    state,
    handlers,
  }
}

export default useCertificatesDownload;