import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  collaborators: [],
  regularCertificates: [],
  certificatesWithSign: [],
  receiverList: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS:
      return { ...state, isLoading: true };
    case actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        collaborators: action.payload.collaborators,
        regularCertificates: action.payload.regularCertificates,
        receiverList: action.payload.receiverList,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_REGULAR_CERTIFICATES_AND_COLLABORATORS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_CERTIFICATES_WITH_SIGN:
      return { ...state, isLoading: true };
    case actionTypes.GET_CERTIFICATES_WITH_SIGN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        certificatesWithSign: action.payload.certificatesWithSign,
        receiverList: action.payload.receiverList,
      };
    case actionTypes.GET_CERTIFICATES_WITH_SIGN_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE:
      return { ...state, isLoading: true };
    case actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        collaborators: action.payload.collaborators,
        locale: action.payload.locale,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_COLLABORATORS_FOR_CERTIFICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_MULTIPLE_CERTIFICATES_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SIGN_AND_DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.SIGN_AND_DOWNLOAD_FULFILLED:
      let auxCollaborators = [...state.collaborators]
      let generatedDocuments = action.payload.docs;

      // sacar los colaboradores con el documento firmado
      auxCollaborators = auxCollaborators.filter((collaboratorDocument) => {
        return (
          !generatedDocuments.some((generatedDocument) => {
            return (
              collaboratorDocument.dataDoc.code === generatedDocument.code &&
              collaboratorDocument.dataDoc.tipoDoc === generatedDocument.tipoDoc &&
              collaboratorDocument.dataDoc.codRef === generatedDocument.codRef &&
              collaboratorDocument.dataDoc.dataCode === generatedDocument.dataCode &&
              collaboratorDocument.dataDoc.destinataries === generatedDocument.destinataries &&
              collaboratorDocument.dataDoc.approvants === generatedDocument.approvants
            );
          })
        );
      });

      return {
        ...state,
        isLoading: false,
        collaborators: auxCollaborators,
      };
    case actionTypes.SIGN_AND_DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
